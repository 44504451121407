@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
    font-family: 'Proxima Nova';
    src: url('/path/to/your/font/ProximaNova-Regular.woff2') format('woff2'),
        url('/path/to/your/font/ProximaNova-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-roboto mt-0 font-normal;
  
}

@layer base {
  html {
    font-family: "Roboto", sans-serif;
  }

  p {
    @apply text-[14px] text-left font-normal leading-[24px] text-[#8a8a8a] mb-[15px];
  }
}

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.rotate-z-270 {
  transform: rotateZ(270deg);
  transform-origin: center;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


.custom-transform>div {
  @apply transition-transform duration-700;
  transform: translateY(-20%);
}

.custom-transform:hover>div {
  @apply transition-transform duration-700;
  transform: translateY(0);
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 8px;
}



.slick-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  /* text-align: center !important; */
}

.slick-dots li {
  display: inline-block;
  margin-right: 10px;
}

.slick-dots li:last-child {
  margin-left: 10px;
}

.slick-dots li button {
  background: #133382;
  border: 0;
  text-indent: -99999999999px;
  padding: 0;
  width: 20px;
  height: 5px;
  border-radius: 3px;
  transition: 0.5s;
}

.slick-dots li.slick-active button {
  width: 40px !important;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.slick-arrow {
  position: absolute;
  top:30%;
  transform: translateY(-50%);
  background: 0;
  border: 0;
  text-transform: uppercase;
  padding: 0;
  font-size: 30px;
  font-weight: 700;
  color: #133382;
  opacity: 0;
  transition: 0.4s;
  z-index: 1;
}

.slick-arrow svg {
  font-size: 35px;
  font-weight: 900;
}

.slick-arrow:hover {
  color: #133382;
}

.slider-area:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.slick-arrow:hover::before {
  width: 30px;
}

.slick-next {
  right: 40px;
}

.slick-prev {
  z-index: 1;
  left: 40px;
}

.bg-img-1 {
  background-image: url("./img/counter/bg_img.png");
}

.bg-img-2 {
  background-image: url("./img/bg3_img.png");
}

.aos-animate[data-aos="fade-right"] {
  transform: translateX(0);
  opacity: 1;
}

[data-aos="fade-right"] {
  transform: translateX(-900px);
  opacity: 0;
  transition-property: transform, opacity;
}

.aos-animate[data-aos="fade-up"] {
  transform: translateY(0);
  opacity: 1;
}

[data-aos="fade-up"] {
  transform: translateY(500px);
  opacity: 0;
  transition-property: transform, opacity;
}

.aos-animate[data-aos="fade-left"] {
  transform: translateX(0);
  opacity: 1;
}

[data-aos="fade-left"] {
  transform: translateX(200px);
  opacity: 0;
  transition-property: transform, opacity;
}

.aos-animate[data-aos="fade-down"] {
  transform: translateY(0);
  opacity: 1;
}

[data-aos="fade-down"] {
  transform: translateY(-600px);
  opacity: 0;
  transition-property: transform, opacity;
}

.custom-fade-up {
  transform: translateY(50px);
  opacity: 0;
  transition-property: transform, opacity;
}

.aos-animate.custom-fade-up {
  transform: translateY(0);
  opacity: 1;
}

.arrow {
  transform: translateY(500%);
}

.sticky {
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 0px 0;
  background: #fff;
  transition: ease-out 0.6s;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}


@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.modal-enter {
  opacity: 0;
  transform: scale(0);
}

.modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 600ms, transform 600ms;
}

.modal-exit {
  opacity: 1;
  transform: scale(1);
}

.modal-exit-active {
  opacity: 0;
  transform: scale(0);
  transition: opacity 600ms, transform 600ms;
}


.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: #133282;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s
}

.input100:focus~.focus-input100::before {
  width: 100%
}

.focus-input100 {
  color: #adadad;
}

input:focus::placeholder {
  color: transparent;
}

.login100-social-item:hover {
  color: #fff;
  background-color: #333 !important
}

.icon-container {
  width: 30px;
  height: 30px;
  perspective: 100px;
  /* cursor: pointer; */
}

.inner-box {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  /* transform-style: preserve-3d; */
}

.icon-front,
.icon-front {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  backface-visibility: hidden;
}

.icon-front {
  z-index: 1;
  /* transform-style: preserve-3d; */
}

i {
  display: inline-block;
  /* padding: 6px; */
  /* background: #fff; */
  transition: transform 0.5s;
}

.icon-back i {
  color: #f54b8a;
  z-index: 10;
  /* transform: rotateX(180deg); */
  transition: transform 0.5s;
  transform: translateY(20px);
  opacity: 0;
}

.input100:focus~.icon-container .icon-front {
  transform: translateY(20px);
  opacity: 0;
}

.input100:focus~.icon-container .icon-back i {
  transform: translateY(0px);
  opacity: 1;
}

label:focus-within {
  font-weight: bold;
}

.form-container {
  /* padding-top: 2rem; */
  perspective: 1700px;
}

.inner-form {
  transform-style: preserve-3d;
  transition: all 0.7s cubic-bezier(.5, 1.3, .5, 1.3);
}

.form-front,
.form-back {
  /* transform-style: preserve-3d; */
  transition: all 0.7s;
}

.form-front {
  backface-visibility: hidden;
}

.form-back {
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

span {
  transform: translateZ(50px);
}

/* .line {
  width: 200px;
} */

.stripesLoader {
  margin: 4px;
  height: 10px;
  /*should not be larger than 20px*/
  position: relative;
  background: linear-gradient(90deg, #eee 50%, transparent 50%);
  background-color: crimson;
  background-size: 200%;
  background-position: 80%;
  border-radius: 5px;
  transform: scale(-1);
  transition: 0.3s;
}

.stripesLoader:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(45deg,
      transparent 25%,
      rgba(238, 238, 238, 0.5) 25%,
      rgba(238, 238, 238, 0.5) 30%,
      transparent 30%,
      transparent 35%,
      rgba(238, 238, 238, 0.5) 35%,
      rgba(238, 238, 238, 0.5) 70%,
      transparent 70%);
  animation: shift 2s linear infinite;
  background-size: 20px 100%;
  box-shadow: inset 0 0px 1px rgba(0, 0, 0, 0.2),
    inset 0 -2px 1px rgba(0, 0, 0, 0.2);
}

@keyframes shift {
  to {
    background-position: 60px 100%;
  }
}

/* .calendar-container {
  max-width: 100%;
  margin: 0 auto;
} */

@media only screen and (max-width: 1200px) {
  .fc-direction-ltr {
    /* height: 500px; */
    width: 100%;
  }
}

.fc-toolbar-chunk .fc-today-button {
  background-color: #133282;
  /* color: #f54a8d; */
}

.fc-toolbar-chunk .fc-today-button:hover {
  background-color: #133282;
  opacity: 0.8;
  /* color: #f54a8d; */
}

.fc-toolbar-chunk .fc-today-button:disabled {
  /* color: #f54a8d; */
  background-color: #133282;
}

.fc-toolbar-chunk .fc-prev-button, .fc-toolbar-chunk .fc-next-button {
  color: #f54a8d;
  background-color: white;
  border-color: white;
}

.fc-toolbar-chunk .fc-prev-button, .fc-toolbar-chunk .fc-next-button   {
  /* background-color: ; */
  border: none;
}
.fc-toolbar-chunk .fc-prev-button:hover, .fc-toolbar-chunk .fc-next-button:hover {
  background-color: white;
  border-color: white;
  color: #f54a8d;
  opacity: 0.8;
}

.fc-toolbar-chunk .fc-button-group .fc-prev-button button:focus  {
  /* // Remove focus around button */
  background-color: white;
  border-style: none;
  outline:none;  
}

.fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  font-size: 1.2em;
  /* color: #172b4c; */
}

/* .fc-col-header-cell {
  color: #172b4c;
}
 */
/* .fc-daygrid-body{
 height: 100px;
} */
@media only screen and (min-width: 1200px) {
  .fc-daygrid-body table tr td .fc-daygrid-day-frame {
    height: 40px;
    /* display: none; */
  }
}

.fc-daygrid-body table tr td .fc-daygrid-day-frame .fc-daygrid-day-events {
  display: none;
}

/* .fc-daygrid-body table tr td {
  height: 40px;
} */

.slick-slide img {
  display: inline-block;
}